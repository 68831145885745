// footer {
//     display: none;
// }
@import '../site/variables-v3';
@import '../site/common-v3';

#search {
  .filter-bar {
    font-size: 14px;

    .filter-block {
      //display: inline-block;
      //margin-right: 10px;
      //margin-bottom: 10px;

      &.search-block {
        margin-left: 0px;
      }

      &.additional-search-option {
        padding-left: 3px;
        display: inline-block;
        margin-top: 10px;
        color:$color-black-new;
      }
    }

    .match-filter-block {
      display: none;
      margin-right: 8px;

      .match-percentage-na {
        display: inline-block;
        margin-top: 5px;
      }
    }

    .search-block {
      width: 250px;
      // border-bottom: 1px solid #eee;

    }

    .right-block {
      float: right;
      margin-top: 19px;

      > .filter-block {
        display: inline-block;
        margin-left: 5px;
      }
    }

    .advanced-block,
    .sort-block {
      border: 1px solid #CFD5DE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 8px 15px;

      label {
        padding: 0;
        margin: 0;
      }
    }

    .advanced-block {

      .advanced-panel {
        display: none;
        position: absolute;
        background: white;
        width: 340px;
        padding: 15px;
        z-index: 10;
        font-size: 12px;

        .top-buttons {
          > div {
            display: inline-block;
            cursor: pointer;
          }

          img {
            width: 20px;
            height: 20px;
            vertical-align: middle;
          }

          .reset-button {
            color: rgb(18, 78, 120);

            img {
              margin-right: 5px;
            }
          }
        }

        .advanced-filters {

          .filter-block {
            display: block;
            margin-bottom: 16px;

            label {
              width: 100px;
              vertical-align: middle;
              font-weight: 600;
              margin-bottom: 5px;
            }

            .field {
              display: inline-block;
              vertical-align: middle;
              /* width: calc(100% - 110px);*/
              width: 100%;
              label{
                font-weight: normal;
              }
            }
          }

          .filter-categories {
            .filter-category {
              cursor: pointer;

              > .category-title {
                font-weight: bold;
                color: $color-black-new;
                padding: 10px 0;
                font-size: 18px;

                &:after {
                  content: '';
                  width: 15px;
                  height: 10px;
                  background-image: url('/img/icons/expand.svg');
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-position: center;
                  transform: scaleY(-1); // Reverse the icon
                }
              }

              > .category-content {
                display: none;
              }

              &.selected {
                > .category-title:after {
                  transform: scaleY(1); // Reverse the icon
                }

                > .category-content {
                  display: block;
                }
              }
            }
          }
        }

        .bottom-buttons {
          display: flex;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          width: 100%;
          background-color: #ffffff;
          left: 0;
          padding: 1rem 0;
          box-shadow: 0 2px 10px rgba(1,1,1,.1);

          button {
            box-shadow: 0px 1px 0px rgba(100, 100, 100, 0.16);
            padding: .5rem 1rem;
            min-width: 10vw;
            border-radius: 10px;
            background-color: $color-blue-new;
            height: 38px;
            border: none;
            color: $color-white;
          }

          .close-button,
          .reset-button {
            background: none;
            box-shadow: none;
            color: $color-blue-new;
            font-weight: 600;
          }
          .close-button{
            display: none;
          }
        }

        .form-control,
        .checkbox-icon {

          border: 1px solid #CFD5DE;
        }

        .checkbox-icon {
          margin-top: 0;
        }

        input[type="checkbox"]:checked + .checkbox-icon {
          background: #CFD5DE;
          border: 1px solid #CFD5DE;
        }
      }
    }

    .sort-block {
      .sort-list {
        display: none;
        position: absolute;
        background: white;
        border: 1px solid #eee;
        z-index: 10;
        font-size: 12px;

        &:before, &:after {
          bottom: 100%;
          left: 15px;
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:before {
          border-color: rgba(204, 204, 204, 0);
          border-bottom-color: #ccc;
          border-width: 9px;
          margin-left: -9px;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: #FFF;
          border-width: 8px;
          margin-left: -8px;
        }

        .sort-item {
          padding: 10px;
          cursor: pointer;
          font-weight: bold;
          color: #096bb0;

          &:hover {
            background-color: #f5f5f5;
          }

          &[data-selected="1"] {
            color: black;
          }
        }
      }
    }

    .switch-view {
      float: right;
      margin-left: 5px;
      background-color: inherit;
      color: #096bb0;
      cursor: pointer;
      font-weight: bold;
      padding: 5px 10px;

      img {
        width: 14px;
        height: 14px;
        margin-top: -4px;
        margin-right: 2px;
        border: 0;
      }
    }

    label.selectable {
      color: #096bb0;
      cursor: pointer;
    }

    .slider {
      width: calc(100% - 10px);

      .slider-handle[aria-valuenow="0"] {
        margin-left: 0;
      }

      .slider-tick-label {
        &:first-child {
          text-align: left;
          transform: translateX(50%);
        }

        &:last-child {
          text-align: right;
          transform: translateX(-50%);
        }
      }
    }

    .checkbox-item {
      display: inline-block;
      margin-right: 10px;
      margin-top: 8px;
    }

    input[type="checkbox"] {
      margin-right: 5px;
      margin-top: 0;
      vertical-align: middle;
    }

    label {
      margin-right: 5px;
    }

    .to {
      margin: 0 6px;
    }

    .form-control {
      display: inline-block;
      width: 95px;
      padding: 4px;
      height: 32px;
      box-shadow: none;
      border: 1px solid #CFD5DE;
      box-sizing: border-box;
      border-radius: 10px;

      &:focus {
        border-color: #096bb0;
      }
    }

    .autocomplete {
      border: 1px solid #CFD5DE;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 10px;
      margin-right: 6px;

      &-icon {
        position: absolute;
        right: 9px;
        top: 12px;
      }
    }

    .select-narrow .form-control {
      width: 82px;
    }


    input[type="text"] {

    }

    input[type="checkbox"] {
      cursor: pointer;
      opacity: 0;
    }

    .checkbox-icon {
      position: absolute;
      margin-left: -20px;
      margin-top: 2px;
      width: 18px;
      height: 18px;
      background: white;
      pointer-events: none;
      transition: background .3s;
      border: 1px solid #ccc;
      border-radius: 3px;

      &:after {
        content: '';
        opacity: 0;
        position: absolute;
        transform: rotate(-45deg);
        top: 4px;
        left: 3px;
        width: 10px;
        height: 5px;
        border: 2px solid #fff;
        border-top-style: none;
        border-right-style: none;
        transition: .3s;
      }
    }

    input[type="checkbox"]:checked + .checkbox-icon {
      border: 1px solid #096bb0;
      background: #096bb0;

      &:after {
        opacity: 1;
      }
    }

    .full-filter-bar-container {

      margin-top: 24px;

      .full-filter-container {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        position: relative;
        border-bottom: 1px solid #DADADA;
        padding-bottom: 15px;

        .filter-section-head {
          h3 {
            color: #435160;
            font-size: 36px;
          }
        }

        .filter-section-container {
          display: inline-block;
          vertical-align: top;
          margin-right: -4px;


          .filter-section-head {
            height: 28px;
            font-weight: bold;
            padding-top: 3px;

            > span.sub-text {
              font-weight: 400;
            }

            &.large {
              padding-top: 0px;
            }
          }

          .filter-section-body {
            height: 40px;
            background-color: white;

            &.section-borders {
              border: 1px solid #d7d7d7;
            }

            &.section-paddings {
              padding: 3px 10px;

              &--right {
                padding: 3px 10px 3px 0;
              }
            }


            &.bottom-shadow {
              box-shadow: 0px 1px 0px rgba(100, 100, 100, 0.16);
            }

            &.last-shadow {
              box-shadow: 0px 1px 5px rgba(100, 100, 100, 0.16);
            }

            &.left-radius {
              border-radius: 2px 0px 0px 2px;
            }

            &.right-radius {
              border-radius: 0px 2px 2px 0px;
            }


            .filter-bar-submit-button {
              box-shadow: 0px 1px 0px rgba(100, 100, 100, 0.16);
              padding: .5rem 1rem;
              width: 80px;
              min-width: 10vw;
              border-radius: 10px;
              background-color: #2979FF;
              height: 38px;
            }

          }

          &.filter-section-first {

          }

          &.filter-section-last {
            margin-right: 12px;
          }

          &.filter-section-search-button {

          }
        }

        .filter-section-search-button {
          position: absolute;
          right: 0;
        }
      }

      .full-filter-container-submit {
        margin-left: 10px;
        display: inline-block;
        vertical-align: top;
      }
    }

    .full-filter-bar-additional {
      display: inline-block;
      margin-top: 13px;
    }


  }

  &.map {
    #map-view { // Hide the map button if already on the map
      display: none;
    }

    #search-list {
      display: none;
    }

    .filter-bar {
      .match-filter-block {
        display: inline-block !important;

        select {
          width: 70px;
        }
      }

      .sort-block {
        display: none;
      }
    }
  }

  &.list { // Enable the map view button
    #list-view { // Hide the list button if already on the list
      display: none;
    }

    #search-map {
      display: none;
    }
  }

  #map {
    .custom-marker {
      font-family: 'Lato', sans-serif;
      background-color: #fc8200;
      color: white;
      border-radius: 6px;
      padding: 3px 4px;
      font-size: 14px;
      transform: translateX(-50%) translateY(-100%);
      margin-top: -7px;
      white-space: nowrap;
      cursor: pointer;

      &:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: #fc8200;
        border-width: 5px;
        margin-left: -5px;
      }

      .property-list {
        display: none;
      }
    }

    .custom-marker-before {
      &:before {
        top: -4%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: transparent;
        border-top-color: white;
        border-width: 5px;
        margin-left: -5px;
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      &:after {
        display: none;
      }
    }
  }

  #map .custom-marker .property-list,
  #map-mobile-selected-property {
    .property-item {
      margin: 0;
      width: 240px;
      background-color: white;
      border-right: 1px solid white;

      .property-details {
        display: block;
        padding: 8px;

        .address {
          display: block;
          margin-top: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #ADB5BE;
        }
      }
    }
  }

  #search-list {
    text-align: center;


    .property-item {
      margin: 10px 6px;
      text-align: left;
      border-radius: 10px;

      .property-details {

        .organization,
        .address {
          display: block;
          max-width: calc(100% - 71px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 4px;
        }

        .organization {
          color: #ADB5BE;
        }


        .full-infos {
          display: none;
        }

        .short-infos {
          float: right;
          font-weight: bold;
        }
      }
    }

    a.property-item.property-inline-hover:hover {
      transform: scale(1.03);
      transition: all 0.2s ease-in-out;
    }

    .personalize-my-experience {
      display: none;
    }

    #listing-pagination {

      .pagination {

        li {
          a, span {
            padding: 8px 6px;
            border: none;
            background-color: inherit;
          }

        }

        > .active {
          background-color: #096bb0;
        }
      }
    }
  }


}

#channel-checker {
  margin-bottom: 20px;
  text-align: left;

  .channel-checker-container {

    .channel-checker-header {

    }

    .inline-property-list-container {

      .inline-property-list {
        width: calc(100%);
      }
    }
  }
}

#map-quiz-modal {
  .modal-text {
    padding: 10px;
  }

  .home-matchmaker {
    background-color: white;
    color: #ffa400;
    border: 1px solid #ffa400;
    border-radius: 4px;
    display: block;
    margin: 15px auto 10px;
    padding: 5px 8px;
    font-weight: bold;
    width: fit-content;

    img {
      margin-right: 5px;
      vertical-align: bottom;
    }
  }
}

// Desktop specific

.filter-bar {
  .sort-block {
    .selected-text {
      display: inline-block;
      cursor: pointer;
      background-color: inherit;
      font-weight: 600;
      color: #435160;
    }
  }
}

@media (min-width: 768px) {
  #search {
    .filter-bar {
      .sort-block {
        .selected-text {
        }

        .sort-list {
          margin-left: 20px;
          margin-top: 10px;

          &:before,
          &:after {
            left: 50%;
          }
        }
      }


      .advanced-block {
        label.selectable {
          background-color: inherit;
          color: #435160;
          font-weight: 600;

          img {
            width: 14px;
            height: 14px;
            margin-top: -4px;
            margin-right: 2px;
            //filter: brightness(0) invert(1); // Make the icon white
          }
        }

        &.open label.selectable {
          background-color: white;

          img {
            filter: none;
          }
        }

        .advanced-panel {
          right: 5px;
          margin-top: 5px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

          &:before {
            content: "";
            position: absolute;
            width: 100%;

            height: 40px;
            left: 0;
            top: 0;
          }

          .top-buttons {
            position: absolute;
            top: 0;
            right: 15px;
            padding: 10px;
            z-index: 1;
            font-weight: bold;

            > img {
              margin-top: -5px;
            }

            .close-button {
              display: none;
            }
          }

          .advanced-filters {
            > .filter-block {
              display: none;
            }

            .filter-categories {
              position: relative;
              padding-top: 50px; // Space for the categories  (which is position absolute)

              .filter-category {
                > .category-title {
                  position: absolute;
                  top: -14px;
                  box-sizing: border-box;
                  cursor: pointer;
                  color: $color-black-new;

                  &:after {
                    display: none;
                  }
                }

                &:nth-child(2) > .category-title {
                  margin-left: 110px;
                }

                &:nth-child(3) > .category-title {
                  margin-left: 300px;
                }

                &.selected > .category-title {

                }
              }
            }
          }
        }
      }
    }

    &.map {
      .page-header {
        display: none;
      }

      .filter-bar {
        margin: 14px 0;
      }

      #map {
        height: calc(100vh - 217px) !important;
      }
    }

    #map {
      .custom-marker {
        &.selected {
          background: white;
          color: black;
          padding: 0;
          position: relative;
          z-index: 1;

          &:after {
            border-top-color: white;
          }

          .text {
            display: none;
          }

          .property-list {
            display: block;
            border-radius: 6px;
            overflow: hidden;
          }
        }
      }
    }


    #map-mobile-selected-property {
      white-space: nowrap;
      overflow-x: auto;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
    }
  }
}

@media (min-width: 1801px) {
  #search-list .property-item {
    width: calc(20% - 15px); // 5 per line
  }
}

@media (min-width: 1201px) and (max-width: 1800px) {
  #search-list .property-item {
    width: calc(33% - 15px); // 4 per line
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  #search-list .property-item {
    width: calc(33% - 12px); // 3 per line
  }
}

// Mobile specific
@media (max-width: 767px) {
  #search {
    .filter-bar {
      > .filter-block {
        &:not(.advanced-block):not(.search-block):not(.sort-block) {
          display: none;
        }

        &.search-block:not(.focus) {
          width: calc(100% - 210px);
        }
      }
      .full-filter-container{
        border-bottom-width: 0 !important;
      }

      .bottom-buttons {
        position: absolute;
        top: 0;
        background-color: $color-white;
        width: 100%;
        justify-content: space-between;
        padding: 10px 0 !important;
        box-shadow: none;
        .close-button{
          display: block !important;
        }


      }

      .right-block {
        margin-top: 0;
        > .filter-block {
          margin-top: 5px;
        }
      }

      .search-block {
        width: 100%;
      }


      .advanced-block {
        label img {
          display: none;
        }

        .advanced-panel {
          position: fixed;
          flex-direction: column;
          width: 100%;
          height: 100%; // Whole page except header
          top: 0;
          left: 0;
          overflow: hidden;
          padding: 72px 0 0 0;
          z-index: 99999;

          > div {
            padding: 0 15px;
          }

          .top-buttons {
            padding: 10px 15px;
            border-bottom: 1px solid #eee;
            margin-bottom: 10px;

            .close-button {
              float: right;
            }

            .reset-button {
              font-size: 14px;
            }
          }

          .advanced-filters {
            flex: 1 1 auto;
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0 16px 24px;
            height: 100%;

            .filter-block {
              position: relative;
            }

            .filter-categories {
              .filter-category {
                > .category-title {
                  border-top: 1px solid rgb(234, 234, 234);
                  font-size: 16px;

                  &:after {
                    float: right;
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }


        }
      }


    }


    // .page-header {
    //     margin-left: 15px;
    // }

    #search-list {
      .property-item {
        width: calc(100% - 15px);

        &:after {
          background-size: 20px;
        }


        &:nth-of-type(5n - 4) {
          width: calc(100% - 15px);

          .property-photo-container {

          }
        }

        .infos {
          // padding: 5px;
        }
      }

      .personalize-my-experience {
        display: block;
        margin: 10px;
      }
    }

    .advanced-block {
      background-color: $color-blue-new;

      label.selectable {
        color: $color-white;
      }

    }

    &.map {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: 1040;

      .page-header {
        display: none;
      }

      .filter-bar {
        margin: 14px 0;

        .filter-block {
          margin-bottom: 0;
        }

        .match-filter-block {
          width: calc(100% - 200px);
        }

        .search-block {
          border: none;

          &:not(.focus) {
            width: auto;
          }

          .autocomplete-input {
            //display: none;
          }
        }
      }
    }

    #map {
      .custom-marker.selected {
        background-color: rgb(255, 164, 0);

        &:after {
          border-top-color: rgb(255, 164, 0);
        }
      }
    }

    #map-mobile-selected-property {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;

      white-space: nowrap;
      overflow-x: auto;

      &:empty {
        display: none;
      }

      .property-item {
        width: 80vw;

        &:only-child {
          margin-left: 10vw;
        }
      }
    }
  }

  .full-filter-bar-container {
    width: 100%;
    margin-bottom: 10px;

    .full-filter-container {
      width: 100%;

      .filter-section-container {
        width: 100%;

        .filter-section-body {
          width: 100%;
        }

      }
    }
  }
}